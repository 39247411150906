<template>
  <van-cell :value="value" :is-link="isLink" @click="handleClick">
    <template #title>
      {{ title }}

      <van-popover v-model="showPopover" trigger="click" placement="right">
        <div class="popoverContent">{{ tip }}</div>
        <template #reference>
          <van-icon ref="icon" class="icon" name="question-o" />
        </template>
      </van-popover>
    </template>
  </van-cell>
</template>

<script>
export default {
  props: {
    title: String,
    value: [String, Number],
    tip: String,
    isLink: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      showPopover: false
    }
  },

  methods: {
    handleClick(e) {
      if (e.target !== this.$refs.icon) {
        this.$emit('click')
      }
    }
  }
}
</script>

<style scoped>
.popoverContent {
  width: 180px;
  padding: 10px;
  font-size: 14px;
  color: #333;
}

.icon {
  color: #ff9933;
  font-size: 16px;
}
</style>
