<template>
  <div class="container" v-show="detail.projectCode">
    <van-nav-bar fixed title="服务费明细" left-arrow @click-left="onClickLeft" />
    <div class="task flexBox" @click="goTaskDetail">
      <div class="taskName">{{ detail.positionName }}</div>
      <div class="icon" v-if="detail.showDetail">
        <van-icon name="arrow" />
      </div>
    </div>

    <div class="topBox flexBox">
      <div class="boxItem">
        <div class="value">{{ detail.computeMonth }}</div>
        <div class="label">所属月份</div>
      </div>
      <div class="line"></div>
      <div class="boxItem">
        <div class="value">{{ detail.paidInMoney | money }}</div>
        <div class="label">服务费(元)</div>
      </div>
    </div>

    <van-cell-group inset>
      <TooltipCell
        v-if="showMap.basicTaskServiceFee"
        title="基础服务费(元)"
        :value="detail.basicTaskServiceFee.fee | money"
        :isLink="true"
        tip="提交并审核通过的基础任务的服务费"
        @click="handleClick('basic', detail.basicTaskServiceFee.fee, detail.basicTaskServiceFee.basicTaskCode)"
      />
      <TooltipCell
        v-if="showMap.temporaryTaskServiceFee"
        title="临时任务服务费(元)"
        :value="detail.temporaryTaskServiceFee.fee | money"
        :isLink="true"
        tip="提交并审核通过的临时任务的服务费"
        @click="
          handleClick('temp', detail.temporaryTaskServiceFee.fee, detail.temporaryTaskServiceFee.temporaryTaskCode)
        "
      />
      <!-- todo 销量激励(元) -->
      <van-cell v-if="showMap.salesBonus" title="销量激励(元)" :value="detail.salesBonus | money" />
      <van-cell v-if="showMap.otherExpenses" title="其他费用(元)" :value="detail.otherExpenses | money" />
    </van-cell-group>

    <van-cell-group inset style="margin-top: 15px" v-if="showMap.planDays || showMap.actualEligibleActiveDays">
      <TooltipCell v-if="showMap.planDays" title="计划活跃数" :value="detail.planDays" tip="计划执行任务的天数" />

      <TooltipCell
        v-if="showMap.actualEligibleActiveDays"
        title="活跃合格数"
        :value="detail.actualEligibleActiveDays"
        tip="实际执行任务合格的天数（需完成开始任务与结束任务并且定位正常）"
      />
    </van-cell-group>

    <van-cell-group inset style="margin-top: 15px" v-if="showMap.planSales || showMap.actualSales">
      <van-cell v-if="showMap.planSales" title="销量目标" :value="detail.planSales | money" />
      <van-cell v-if="showMap.actualSales" title="实际销量" :value="detail.actualSales | money" />
    </van-cell-group>

    <van-cell-group inset style="margin-top: 15px">
      <van-cell title="联系人" :value="detail.moName" is-link @click="showDialog = true" />
    </van-cell-group>

    <van-dialog v-model="showDialog">
      <div class="dialogContent">
        <div>联系人姓名：{{ detail.moName }}</div>
        <div>联系人电话：{{ detail.moTel }}</div>
      </div>
    </van-dialog>
  </div>
</template>

<script>
import { Toast } from 'vant'
import TooltipCell from './components/TooltipCell.vue'
import { taskChargeDetail } from '@/api/charge'

export default {
  components: { TooltipCell },
  data() {
    return {
      showPopover: false,
      showDialog: false,

      detail: {}
    }
  },
  created() {
    this.getData()
  },
  computed: {
    showMap() {
      return {
        basicTaskServiceFee: this.detail.showBasicTaskServiceFee === 1 && this.detail.basicTaskServiceFee.fee != null,
        temporaryTaskServiceFee:
          this.detail.showTemporaryTaskServiceFee === 1 && this.detail.temporaryTaskServiceFee.fee != null,
        salesBonus: this.detail.showSalesBonus === 1 && this.detail.salesBonus != null,
        otherExpenses: this.detail.showOtherExpenses === 1 && this.detail.otherExpenses != null,
        planDays: this.detail.showPlanDays === 1 && this.detail.planDays != null,
        actualEligibleActiveDays:
          this.detail.showActualEligibleActiveDays === 1 && this.detail.actualEligibleActiveDays != null,
        planSales: this.detail.showPlanSales === 1 && this.detail.planSales != null,
        actualSales: this.detail.showActualSales === 1 && this.detail.actualSales != null
      }
    }
  },
  methods: {
    onClickLeft() {
      location.href = 'javascript:history.back();'
      // Toast("返回");
    },

    handleClick(type, fee, code) {
      const { projectCode, terminalCode, positionName, showDetail, computeMonth: reportDate } = this.detail
      const taskBatchCode = code || []
      this.$router.push({
        path: '/chargeDetailList',
        query: {
          type,
          fee,
          projectCode,
          terminalCode,
          positionName,
          taskBatchCode,
          showDetail,
          reportDate
        }
      })
    },

    getData() {
      console.log(this)
      Toast.loading({
        message: '加载中...',
        forbidClick: true,
        duration: 0
      })
      const { saleSalaryId, projectCode, terminalCode } = this.$route.query
      taskChargeDetail({
        saleSalaryId,
        projectCode,
        terminalCode
      })
        .then(({ data: res }) => {
          console.log('res: ', res)
          Toast.clear()
          if (res.success) {
            this.detail = res.data
          }
        })
        .catch((err) => {
          Toast.clear()
          console.log('err: ', err)
        })
    },

    goTaskDetail() {
      if (this.detail.showDetail !== 1) return
      const { projectCode, terminalCode, basicTaskServiceFee, temporaryTaskServiceFee } = this.detail

      let taskBatchCode = ''
      if (basicTaskServiceFee && basicTaskServiceFee.basicTaskCode && basicTaskServiceFee.basicTaskCode.length) {
        taskBatchCode = basicTaskServiceFee.basicTaskCode[0]
      } else if (
        temporaryTaskServiceFee &&
        temporaryTaskServiceFee.temporaryTaskCode &&
        temporaryTaskServiceFee.temporaryTaskCode.length
      ) {
        taskBatchCode = temporaryTaskServiceFee.temporaryTaskCode[0]
      }

      this.$router.push({
        path: '/taskDetail',
        query: {
          projectCode,
          terminalCode,
          taskBatchCode
        }
      })
    }
  }
}
</script>
<style lang="less" scoped>
.container {
  padding: 44px 0 20px;
}
.flexBox {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.task {
  height: 44px;
  background-color: #fff;
  border-bottom: 1px solid #ececec;
  padding: 0 15px;
}
.taskName {
  font-size: 14px;
  font-weight: bold;
  color: #333;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.topBox {
  background-color: #fff;
  box-sizing: border-box;
  padding: 20px 10px;
  font-size: 14px;
  color: #333;
  margin-bottom: 20px;
  .boxItem {
    text-align: center;
    flex: 1;
    .value {
      font-size: 18px;
      color: #0099ff;
    }

    .label {
      margin-top: 10px;
      font-size: 14px;
      color: #666;
    }
  }

  .line {
    height: 30px;
    border-left: 1px solid #ececec;
    flex-shrink: 0;
  }
}

.dialogContent {
  padding: 30px 20px 20px;
  font-size: 14px;
  color: #333;
  line-height: 24px;
}
</style>
