import request from '../utils/request.js'

const emptyPromise = () => Promise.resolve(null)

// 我的任务服务费
export function taskCharge(params = {}) {
  const empPk = sessionStorage.getItem('empPk')
  if (!empPk) return emptyPromise()
  return request().get('/api/con/v1/task/taskCharge', {
    params: {
      empPk,
      ...params
    }
  })
}

// 服务费明细
export function taskChargeDetail(params = {}) {
  const empPk = sessionStorage.getItem('empPk')
  if (!empPk) return emptyPromise()
  return request().get('/api/con/v1/task/taskChargeDetail', {
    params: {
      empPk,
      ...params
    }
  })
}

// 我的任务服务费
export function taskDetail(data = {}) {
  const empPk = sessionStorage.getItem('empPk')
  if (!empPk) return emptyPromise()
  return request().post('/api/con/v1/task/taskDetail', {
    empPk,
    ...data
  })
}

// 基础任务服务费，临时任务服务费
export function myTask(data = {}) {
  const empPk = sessionStorage.getItem('empPk')
  if (!empPk) return emptyPromise()
  return request().post('/api/con/v1/task/myTask', {
    empPk,
    ...data
  })
}
